<template>
  <div class="aboutus">
    <HeroVideo />
    <div class="about-org py-5" v-if="type == 'desktop'">
      <div class="container-xl">
        <div class="row">
          <div class="col-6">
            <img
              src="@/assets/img/landing/about organiser/about.png"
              class="img-fluid"
              alt=""
            />
            <p>
              The Centre for Healthcare Innovation (CHI) Co-Learning Network was
              launched in October 2016 and currently has thirty-eight (38) local
              and international partners from Academia, Strategic Agencies,
              Healthcare Clusters, Community Partners and Industry Knowledge
              Partners. CHI is hosted by Tan Tock Seng Hospital with the
              following three (3) strategic thrusts:
            </p>
            <ul>
              <li>
                <h4>TO PROMOTE A CULTURE OF INNOVATION</h4>
                <p>
                  By creating thought leadership; via focused partnerships and a
                  flywheel of co-learning networks
                </p>
              </li>
              <li>
                <h4>TO ACHIEVE BETTER HEALTH AND HEALTHCARE</h4>
                <p>
                  By enabling workforce transformation; via new andragogy and
                  paradigms for our communities of carers and patients
                </p>
              </li>
              <li>
                <h4>TO CATALYSE VALUE-BASED OUTCOMES</h4>
                <p>
                  By effecting systems-level change; via impactful programmes
                  that drive innovation adoption and knowledge translation
                </p>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <img
              src="@/assets/img/landing/about organiser/About organiser image.png"
              alt="chi image"
              class="img-fluid"
            />
          </div>
        </div>

        <p class="mt-5">
          The Network is founded on the concept of co-Learning – the idea that
          we learn better together as a Community of Practice. We are an open
          learning platform, an ecosystem of value-enabling alliances. Through
          our network, like-minded local and overseas innovation partners will
          co-learn and collaborate by co-building thought leadership in
          healthcare innovation, co-transforming the workforce for our future,
          and co-developing new training and andragogy. We will meet current and
          future healthcare challenges through innovative and value-driven care
          delivery to the populations we serve.
        </p>
        <p>
          The Ng Teng Fong Centre for Healthcare Innovation (CHI) is an exciting
          nine (9)-storey conference, training and innovation building that aims
          to transform our healthcare workforce to be future-ready. At
          twenty-five thousand (25,000) sqm, it is Singapore's largest
          purpose-built innovation centre with MICE facilities, simulation and
          innovation labs and engagement spaces.
        </p>
      </div>
    </div>
    <div class="about-org py-5" v-else>
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <img
              src="@/assets/img/landing/about organiser/mobile-about.png"
              alt="chi image"
              class="img-fluid"
            />
          </div>
          <div class="col-12">
            <img
              src="@/assets/img/landing/about organiser/mobile-text.png"
              class="img-fluid"
              alt=""
            />
            <p>
              The Centre for Healthcare Innovation (CHI) Co-Learning Network was
              launched in October 2016 and currently has thirty-eight (38) local
              and international partners from Academia, Strategic Agencies,
              Healthcare Clusters, Community Partners and Industry Knowledge
              Partners. CHI is hosted by Tan Tock Seng Hospital with the
              following three (3) strategic thrusts:
            </p>
            <ul>
              <li>
                <h4>TO PROMOTE A CULTURE OF INNOVATION</h4>
                <p>
                  By creating thought leadership; via focused partnerships and a
                  flywheel of co-learning networks
                </p>
              </li>
              <li>
                <h4>TO ACHIEVE BETTER HEALTH AND HEALTHCARE</h4>
                <p>
                  By enabling workforce transformation; via new andragogy and
                  paradigms for our communities of carers and patients
                </p>
              </li>
              <li>
                <h4>TO CATALYSE VALUE-BASED OUTCOMES</h4>
                <p>
                  By effecting systems-level change; via impactful programmes
                  that drive innovation adoption and knowledge translation
                </p>
              </li>
            </ul>
          </div>
        </div>

        <p class="mt-5">
          The Network is founded on the concept of co-Learning – the idea that
          we learn better together as a Community of Practice. We are an open
          learning platform, an ecosystem of value-enabling alliances. Through
          our network, like-minded local and overseas innovation partners will
          co-learn and collaborate by co-building thought leadership in
          healthcare innovation, co-transforming the workforce for our future,
          and co-developing new training and andragogy. We will meet current and
          future healthcare challenges through innovative and value-driven care
          delivery to the populations we serve.
        </p>
        <p>
          The Ng Teng Fong Centre for Healthcare Innovation (CHI) is an exciting
          nine (9)-storey conference, training and innovation building that aims
          to transform our healthcare workforce to be future-ready. At
          twenty-five thousand (25,000) sqm, it is Singapore's largest
          purpose-built innovation centre with MICE facilities, simulation and
          innovation labs and engagement spaces.
        </p>
      </div>
    </div>
    <div class="org-committee">
      <img
        src="@/assets/img/landing/committee.png"
        class="img-fluid"
        alt=""
        v-if="type == 'desktop'"
      />
      <img
        src="@/assets/img/landing/mobile-Committee.png"
        class="img-fluid"
        alt=""
        v-else
      />
    </div>

    <div class="sci-com-members-desktop" v-if="type == 'desktop'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center py-4">
            <img
              src="@/assets/img/landing/committee/text.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div
            class="col-2 px-3 mb-2"
            v-for="(spk, index) in speakers"
            :key="index"
            @click="openSpeakerModal(spk.dPopup)"
          >
            <img :src="spk.dImg" class="img-fluid" alt="" />
            <p class="text-light speaker-det" v-html="spk.detail"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="sci-com-members-mobile" v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center py-4">
            <img
              src="@/assets/img/landing/committee/text.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div
            class="col-4 px-3 mb-2"
            v-for="(spk, index) in speakers"
            :key="index"
            @click="openSpeakerModal(spk.mPopup)"
          >
            <img :src="spk.mImg" class="img-fluid" alt="" />
            <p class="text-light speaker-det" v-html="spk.detail"></p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <div
      class="modal fade"
      id="speakerModal"
      tabindex="-1"
      aria-labelledby="modalspeakerLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="color: #000"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <img :src="currentSpeakerImage" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "aboutus",
  components: {
    HeroVideo,
    Footer,
  },
  mounted() {},
  data() {
    return {
      type: "desktop",
      currentSpeakerImage: "",

      speakers: [
        {
          dImg: "/landing/desktop/speaker/1.png",
          dPopup: "/landing/desktop/popup/1.png",
          mImg: "/landing/mobile/speaker/1.png",
          mPopup: "/landing/mobile/popup/1.png",
          detail: `Clinical Director<br/>
Centre for Healthcare Innovation
(Chairperson)`,
        },
        {
          dImg: "/landing/desktop/speaker/2.png",
          dPopup: "/landing/desktop/popup/2.png",
          mImg: "/landing/mobile/speaker/2.png",
          mPopup: "/landing/mobile/popup/2.png",
          detail: `Director<br/>
Sector & Partnership Division<br/>
Agency for Integrated Care`,
        },
        {
          dImg: "/landing/desktop/speaker/3.png",
          dPopup: "/landing/desktop/popup/3.png",
          mImg: "/landing/mobile/speaker/3.png",
          mPopup: "/landing/mobile/popup/3.png",
          detail: `Deputy Director<br/>
Centre for Healthcare Innovation`,
        },
        {
          dImg: "/landing/desktop/speaker/4.png",
          dPopup: "/landing/desktop/popup/4.png",
          mImg: "/landing/mobile/speaker/4.png",
          mPopup: "/landing/mobile/popup/4.png",
          detail: `Consultant<br/>
Centre for Healthcare Innovation`,
        },
        {
          dImg: "/landing/desktop/speaker/5.png",
          dPopup: "/landing/desktop/popup/5.png",
          mImg: "/landing/mobile/speaker/5.png",
          mPopup: "/landing/mobile/popup/5.png",
          detail: `Principal<br/>
The Dao of Thriving`,
        },
        {
          dImg: "/landing/desktop/speaker/6.png",
          dPopup: "/landing/desktop/popup/6.png",
          mImg: "/landing/mobile/speaker/6.png",
          mPopup: "/landing/mobile/popup/6.png",
          detail: `Director<br/>
Office for Service Transformation
SingHealth`,
        },
        {
          dImg: "/landing/desktop/speaker/7.png",
          dPopup: "/landing/desktop/popup/7.png",
          mImg: "/landing/mobile/speaker/7.png",
          mPopup: "/landing/mobile/popup/7.png",
          detail: `Senior Director<br/>
Temasek Foundation`,
        },
        {
          dImg: "/landing/desktop/speaker/8.png",
          dPopup: "/landing/desktop/popup/8.png",
          mImg: "/landing/mobile/speaker/8.png",
          mPopup: "/landing/mobile/popup/8.png",
          detail: `Head, Dept of Family Medicine<br/>
National University Health System`,
        },
        {
          dImg: "/landing/desktop/speaker/9.png",
          dPopup: "/landing/desktop/popup/9.png",
          mImg: "/landing/mobile/speaker/9.png",
          mPopup: "/landing/mobile/popup/9.png",
          detail: `Chief Executive Officer<br/>
AWWA`,
        },
        {
          dImg: "/landing/desktop/speaker/10.png",
          dPopup: "/landing/desktop/popup/10.png",
          mImg: "/landing/mobile/speaker/10.png",
          mPopup: "/landing/mobile/popup/10.png",
          detail: `Executive Director<br/>
Central Health<br/>
Tan Tock Seng Hospital`,
        },
        {
          dImg: "/landing/desktop/speaker/11.png",
          dPopup: "/landing/desktop/popup/11.png",
          mImg: "/landing/mobile/speaker/11.png",
          mPopup: "/landing/mobile/popup/11.png",
          detail: `Vice President (Philips SG) <br/>
Philips Design  `,
        },
        {
          dImg: "/landing/desktop/speaker/12.png",
          dPopup: "/landing/desktop/popup/12.png",
          mImg: "/landing/mobile/speaker/12.png",
          mPopup: "/landing/mobile/popup/12.png",
          detail: `Director<br/>
School of X<br/>
DesignSingapore Council`,
        },
      ],
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openSpeakerModal(link) {
      this.currentSpeakerImage = link;
      $("#speakerModal").modal("show");
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutus {
  .pointer {
    cursor: pointer;
  }
  .about-org {
    background: rgb(32, 9, 101);

    background-repeat: no-repeat;
    background-size: cover;
    h2 {
      color: #fff;
    }
    h1 {
      color: #fff;
    }
    h4 {
      color: rgb(254, 65, 197);
    }
    p {
      color: #fff;
    }
    ul li {
      color: rgb(254, 65, 197);
    }
  }

  .sci-com-members-desktop {
    min-height: 100vh;
    background-image: url("../assets/img/landing/committee/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    .speaker-det {
      font-size: 14px;
      font-family: "Nexa-Light";
    }
  }
  .sci-com-members-mobile {
    background-image: url("../assets/img/landing/mobile-speaker/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    p {
      font-size: 14px;
      font-family: "Nexa-Light";
    }
  }
  .org-committee {
    // background-image: url("../assets/img/home/ORGANISING COMMITTEE/bg.png");
    // background-repeat: no-repeat;
    // background-size: cover;

    background: #200965;
  }
  .org-team {
    background: #393333;
    color: #fff;
    h1 {
      margin-bottom: 0px !important;
    }
  }
  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
